const formatDateTimeToGCalendar = dateTime => {
  return dateTime
    .toISOString()
    .split("-")
    .join("")
    .split(":")
    .join("")
    .split(".000")
    .join("")
}

export const getGoogleCalendarLink = data => {
  const baseURL = "https://calendar.google.com/calendar/r/eventedit"
  const eventName = data.event_name
  const startDateTime = formatDateTimeToGCalendar(
    new Date(data.event_start)
  )
  const endDateTime = formatDateTimeToGCalendar(
    new Date(data.event_end)
  )
  const details = data.event_detail
  const link = `${baseURL}?text=${eventName}&dates=${startDateTime}/${endDateTime}&details=${details}`
  return encodeURI(link)
}

export const getOutlookLink = data => {
  const baseURL =
    "https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent"
  const eventName = data.event_name
  const startDateTime = new Date(data.event_start).toISOString()
  const endDateTime = new Date(data.event_end).toISOString()
  const details = data.event_detail
  const link = `${baseURL}&subject=${eventName}&startdt=${startDateTime}&enddt=${endDateTime}&body=${details}`
  return encodeURI(link)
}
