import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { graphql } from "gatsby"
import LanguageContext from "../contexts/language"
import Footer from "../components/footer"
import Seo from "../components/seo"
import ButtonAnchor from "../components/button-anchor/ButtonAnchor"
import HeaderWhite from "../components/HeaderWhite/HeaderWhite"
import RegisterForm from "../components/form/RegisterForm"
import { SEO_DATA } from "../components/seo/seoData"
import {
  getGoogleCalendarLink,
  getOutlookLink,
} from "../components/webinar/webinar.util"

import Layout from "../components/layout"
const isBrowser = typeof window !== "undefined"

const WebinarRegistration = props => {
  const { data, uid } = props.data.prismicWebinarRegistration
  const { lang, theme, accesibilitySize } = useContext(LanguageContext)

  const refBanner = useRef(null)
  const [heightBanner, setHeightBanner] = useState(null)
  const [themeState, setThemeState] = useState(theme)
  const [accesibilitySizeState, setAccesibilitySizeState] = useState(
    accesibilitySize
  );
  const [background, setBackground] = useState(data?.banner_image?.url);

  const seo = SEO_DATA.WEBINAR_REGISTRATION[lang];

  const validateImage = () =>
  setBackground(
      window.innerWidth <= 575.98
        ? data?.banner_image_mobile?.url
        : data?.banner_image?.url
    )

  useEffect(() => {
    if (isBrowser && themeState) localStorage.setItem("foris_theme", themeState)
  }, [themeState])

  useEffect(() => {
    if (isBrowser && accesibilitySizeState)
      localStorage.setItem("accesibility_size", accesibilitySizeState)
  }, [accesibilitySizeState])

  useLayoutEffect(() => {
    if (refBanner.current) {
      const currentHeight = refBanner.current.getBoundingClientRect().height
      setHeightBanner(currentHeight)
    }

    validateImage();
    window.addEventListener(`resize`, validateImage);
  }, [])

  return (
    <Layout
      {...props}
      theme={themeState}
      accesibilitySize={accesibilitySizeState}
    >
      <Seo title={seo.title} desc={seo.description} />
      <HeaderWhite
        className="albusPage_header"
        heightBanner={heightBanner}
        onclick={() =>
          themeState === "theme-light"
            ? setThemeState("theme-dark")
            : setThemeState("theme-light")
        }
        onClickPlus={() => {
          if (accesibilitySizeState < 2) {
            setAccesibilitySizeState(accesibilitySizeState + 1)
          }
        }}
        onClickMinus={() => {
          if (accesibilitySizeState => 2) {
            setAccesibilitySizeState(accesibilitySizeState - 1)
          }
        }}
      />
      <main id="app" className="webinarRegistrationPage" role="main">
        <section className="webinarRegistrationPage_banner" style={{backgroundImage: `url(${background})`}}/>
        <section className="webinarRegistrationPage_form">
          <div className="formContent">
            <RegisterForm
              className="formContent_register"
              title={data.form_title.text}
              confirmButtonText={data.form_button_text}
              uid={`register-${uid}`}
            />
            <div className="formContent_calendar">
              <h2 className="title">{data.calendar_title.text}</h2>
              <div className="buttons">
                <ButtonAnchor
                  className="buttons_item"
                  isButton
                  onClick={() => {
                    window.open(getGoogleCalendarLink(data), "_blank")
                  }}
                >
                  {data.google_button_text}
                </ButtonAnchor>
                <ButtonAnchor
                  className="buttons_item"
                  isButton
                  onClick={() => {
                    window.open(getOutlookLink(data), "_blank")
                  }}
                >
                  {data.outlook_button_text}
                </ButtonAnchor>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer className="albusPage_footer" />
    </Layout>
  )
}

export default WebinarRegistration

export const pageQuery = graphql`
  query WebinarRegistration($lang: String!, $uid: String!) {
    prismicWebinarRegistration(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      data {
        banner_image {
          url
        }
        banner_image_mobile {
          url
        }
        form_title {
          text
        }
        form_button_text
        calendar_title {
          text
        }
        event_name
        event_detail
        event_start
        event_end
        google_button_text
        outlook_button_text
      }
    }
  }
`
